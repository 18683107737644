import React, { Component } from 'react'
import { Link } from 'gatsby'
import { Field, Formik } from 'formik'
import * as Yup from 'yup'

import {
  Button,
  CardFooter,
  CustomInput,
  CustomSelect,
  Field as FormField
} from 'sqrl-design-system'

import {
  EmployeeCountCategory,
  BusinessTypes,
  States,
  BusinessClassifications
} from '../data'

const mainBusinessClassifications = BusinessClassifications.map(d => d.category)

const subCategoryGenerator = category => {
  if (!category) return []
  return BusinessClassifications.filter(t => t.category === category)[0]
    .subCategories
}

const FormSchema = Yup.object().shape({
  employer: Yup.string().required('Employer is required'),
  employeeCount: Yup.string()
    .required('Number of Employees is required')
    .oneOf(EmployeeCountCategory),
  businessType: Yup.string()
    .required('Business type is required')
    .oneOf(BusinessTypes, 'Business type is invalid'),
  taxID: Yup.string().required('Tax ID or EIN is required'),
  businessClassification: Yup.string()
    .required('Business Classfication is required')
    .oneOf(mainBusinessClassifications),
  businessSubClassification: Yup.string().required(
    'Business Sub-Classification is required'
  ),
  streetAddress1: Yup.string().required('Business Street Address is required'),
  streetAddress2: Yup.string(),
  zip: Yup.number()
    .required('Zip Code is required')
    .integer()
    .min(5, 'Input a 5 digit zip code'),
  state: Yup.string()
    .required('State is required')
    .oneOf(Object.keys(States).map(k => States[k]))
})

class SolePropBusinessVerificationForm extends Component {
  render() {
    return (
      <Formik
        initialValues={{}}
        validationSchema={FormSchema}
        onSubmit={(values, { setSubmitting }) => {
          console.log(values)
          setSubmitting(false)
        }}
        render={props => (
          <form
            name="c-corp-personal-verification"
            onSubmit={props.handleSubmit}
          >
            <div className="c-corp-personal-verification-form-container">
              <h1>Business Verification Info</h1>
              <p>
                In order to setup your account properly, please review the
                information you’ve already provided as well as some additional
                details we need in order to verify your personal and business
                identity. We ask for this information solely as a precautionary
                measure for your security.
              </p>
              <hr />
              <div className="details-section">
                <h2>Company Details</h2>

                <div className="divider-1-2">
                  <Field
                    className={
                      props.touched.employer && props.errors.employer
                        ? 'is-invalid'
                        : ''
                    }
                    type="text"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.employer}
                    id="employer"
                    name="employer"
                    label="Employer"
                    component={CustomInput}
                  />

                  <FormField>
                    <CustomSelect
                      label="Number of Employees"
                      name="employeeCount"
                      value={props.values.employeeCount}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    >
                      <option value="" label="" />
                      {EmployeeCountCategory.map((d, i) => (
                        <option value={d} label={d} key={i.toString()} />
                      ))}
                    </CustomSelect>
                  </FormField>
                </div>

                <div className="divider-1-2">
                  <FormField>
                    <CustomSelect
                      label="Type of Business"
                      name="businessType"
                      value={props.values.businessType}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    >
                      <option value="" label="" />
                      {BusinessTypes.map((d, i) => (
                        <option value={d} label={d} key={i.toString()} />
                      ))}
                    </CustomSelect>
                  </FormField>

                  <Field
                    className={
                      props.touched.taxID && props.errors.taxID
                        ? 'is-invalid'
                        : ''
                    }
                    type="text"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.taxID}
                    id="taxID"
                    name="taxID"
                    label="Tax ID or EIN"
                    component={CustomInput}
                  />
                </div>

                <div className="divider-1-2">
                  <FormField>
                    <CustomSelect
                      label="Business Classification"
                      name="businessClassification"
                      value={props.values.businessClassification}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    >
                      <option value="" label="" />
                      {BusinessClassifications.map((d, i) => (
                        <option
                          value={d.category}
                          label={d.category}
                          key={i.toString()}
                        />
                      ))}
                    </CustomSelect>
                  </FormField>

                  <FormField>
                    <CustomSelect
                      label="Sub-Classification"
                      name="businessSubClassification"
                      value={props.values.businessSubClassification}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    >
                      <option value="" label="" />
                      {subCategoryGenerator(
                        props.values.businessClassification
                      ).map((d, i) => (
                        <option value={d} label={d} key={i.toString()} />
                      ))}
                    </CustomSelect>
                  </FormField>
                </div>
              </div>

              <hr />
              <div className="details-section" style={{ width: '75%' }}>
                <h2>Business Address</h2>
                <div>
                  <Field
                    className={
                      props.touched.streetAddress1 &&
                      props.errors.streetAddress1
                        ? 'is-invalid'
                        : ''
                    }
                    type="text"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.streetAddress1}
                    id="streetAddress1"
                    name="streetAddress1"
                    label="Business Street Address"
                    component={CustomInput}
                  />

                  <Field
                    type="text"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.streetAddress2}
                    id="streetAddress2"
                    name="streetAddress2"
                    label="Business Street Address"
                    component={CustomInput}
                  />
                </div>
                <div className="divider-1-2">
                  <Field
                    className={
                      props.touched.zip && props.errors.zip ? 'is-invalid' : ''
                    }
                    type="text"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.zip}
                    id="zip"
                    name="zip"
                    label="Zip Code"
                    component={CustomInput}
                  />

                  <FormField>
                    <CustomSelect
                      label="State"
                      name="state"
                      value={props.values.state}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    >
                      <option value="" label="" />
                      {Object.keys(States).map(key => (
                        <option
                          value={States[key]}
                          label={States[key]}
                          key={key}
                        />
                      ))}
                    </CustomSelect>
                  </FormField>
                </div>
              </div>
            </div>
            <CardFooter
              style={{ alignItems: 'center' }}
              className="on-boarding-form-actions"
            >
              <Link to="/">Save Progress</Link>
              <Button
                isColor={props.isValid ? 'primary' : ''}
                className="is-rounded"
                type="submit"
              >
                Submit
              </Button>
            </CardFooter>
          </form>
        )}
      />
    )
  }
}

export default SolePropBusinessVerificationForm
