import React from 'react'
import classNames from 'classnames'

export default ({ data, current, ...rest }) => {
  const steps = (data || []).map((d, index) => (
    <>
      <div
        className={classNames({
          step: true,
          current: index === current,
          completed: index < current
        })}
      >
        <div className="num">{index < current ? '✓' : index + 1}</div>
        <div className="description">{d}</div>
      </div>
      {index < data.length - 1 && <div className="step-divider" />}
    </>
  ))
  return (
    <div className="steps-container" {...rest}>
      {steps}
    </div>
  )
}
