import React from 'react'
import Steps from '../../Steps'

export default props => (
  <Steps
    data={[
      'Type of Business',
      'Personal Verification Info',
      'Business Verification Info',
      'Review & Confirm'
    ]}
    current={1}
    {...props}
  />
)
