import React, { Component } from 'react'
import { Link } from 'gatsby'
import { Field, Formik, FieldArray, getIn } from 'formik'
import * as Yup from 'yup'
import { generate } from 'shortid'

import {
  CardFooter,
  Button,
  CustomInput,
  CustomSelect,
  Field as FormField,
  Switch
} from 'sqrl-design-system'
import MaskedInput from 'react-text-mask'

import { JobDescriptions, PhoneMask, States } from '../data'

const capitalize = value => value.charAt(0).toUpperCase() + value.slice(1)

const phoneNumberMask = PhoneMask

const jobDescriptions = JobDescriptions

const countries = ['United States', 'India']

const states = States

const OwnerSchema = type => ({
  citizenship: Yup.string()
    .required(`${capitalize(type)} Owner citizen must be set`)
    .oneOf(countries, `${capitalize(type)} Owner citizenship is not valid`),
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  workEmail: Yup.string()
    .required('Email is required')
    .email('Email is not valid'),
  jobTitle: Yup.string()
    .required('Job Title is required')
    .oneOf(jobDescriptions, 'Job Title is not valid'),
  dateOfBirth: Yup.string().required('Date of Birth is required'),
  last4SSN: Yup.string()
    .required('Last 4 SSN digits are required')
    .length(4, 'Enter all 4 last digits of SSN'),
  streetAddress: Yup.string()
    .required('Street address is required')
    .min(10, 'Address must be at least 10 characters long'),
  houseDetail: Yup.string().required('Apt, Floor Suite or Bldg is required'),
  zip: Yup.string()
    .required('Zip code is required')
    .min(6, 'Zip code should be at least 6 digits long'),
  state: Yup.string()
    .required('State is required')
    .oneOf(Object.keys(states).map(k => states[k]))
})

const OwnerObject = {
  citizenship: '',
  firstName: '',
  lastName: '',
  workEmail: '',
  jobTitle: '',
  dateOfBirth: '',
  last4SSN: '',
  streetAddress: '',
  houseDetail: '',
  zip: '',
  state: ''
}

const FormSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  workEmail: Yup.string()
    .required('Email is required')
    .email('Email is not valid'),
  jobDesc: Yup.string()
    .required('Job Description is required')
    .oneOf(jobDescriptions, 'Job Description is not valid'),
  phone: Yup.string()
    .required('Phone is required')
    .length(14),
  primaryOwner: Yup.object().shape(OwnerSchema('primary')),
  hasMultipleOwners: Yup.bool().oneOf([true, false]),
  secondaryOwners: Yup.array().of(Yup.object().shape(OwnerSchema('secondary')))
})

class CCorpPersonalVerificationForm extends Component {
  render() {
    return (
      <Formik
        initialValues={{ hasMultipleOwners: false }}
        validationSchema={FormSchema}
        onSubmit={(values, { setSubmitting }) => {
          console.log(values)
          setSubmitting(false)
        }}
        render={props => (
          <form
            name="c-corp-personal-verification"
            onSubmit={props.handleSubmit}
          >
            <div className="c-corp-personal-verification-form-container">
              <h1>Personal Verification Info</h1>
              <p>
                In order to setup your account properly, please review the
                information you’ve already provided as well as some additional
                details we need in order to verify your personal and business
                identity. We ask for this information solely as a precautionary
                measure for your security.
              </p>
              <hr />
              <div className="details-section">
                <h2>Personal Details</h2>
                <div className="divider-1-2">
                  <Field
                    className={
                      props.touched.firstName && props.errors.firstName
                        ? 'is-invalid'
                        : ''
                    }
                    type="text"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.firstName}
                    id="firstName"
                    name="firstName"
                    label="First Name"
                    component={CustomInput}
                  />

                  <Field
                    className={
                      props.touched.lastName && props.errors.lastName
                        ? 'is-invalid'
                        : ''
                    }
                    type="text"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.lastName}
                    id="lastName"
                    name="lastName"
                    label="Last Name"
                    component={CustomInput}
                  />
                </div>

                <Field
                  className={
                    props.touched.workEmail && props.errors.workEmail
                      ? 'is-invalid'
                      : ''
                  }
                  type="text"
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.workEmail}
                  id="workEmail"
                  name="workEmail"
                  label="Work Email Address"
                  component={CustomInput}
                />

                <Field
                  type="text"
                  name="phone"
                  render={({ field }) => (
                    <FormField>
                      <MaskedInput
                        className={
                          props.touched.phone && props.errors.phone
                            ? 'is-invalid'
                            : ''
                        }
                        {...field}
                        render={(ref, props) => (
                          <CustomInput {...field} {...props} innerRef={ref} />
                        )}
                        mask={phoneNumberMask}
                        guide={false}
                        id="phone"
                        label="Company Phone"
                        type="text"
                      />
                    </FormField>
                  )}
                />

                <FormField>
                  <CustomSelect
                    className={
                      props.touched.jobDesc && props.errors.jobDesc
                        ? 'is-invalid'
                        : ''
                    }
                    name="jobDesc"
                    label="Job Description"
                    value={props.values.jobDesc}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  >
                    <option value="" label="" />
                    {jobDescriptions.map((s, i) => (
                      <option
                        value={s}
                        label={s}
                        key={`JobDesc${i.toString()}`}
                      />
                    ))}
                  </CustomSelect>
                </FormField>
              </div>
              <hr />
              <div className="details-section">
                <h2>Business owner(s)</h2>
                <div>
                  <h3>Primary Owner</h3>
                  <FormField>
                    <CustomSelect
                      label="Country"
                      name="primaryOwner.citizenship"
                      value={
                        props.values.primaryOwner &&
                        props.values.primaryOwner.citizenship
                      }
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    >
                      <option value="" label="" />
                      {Object.keys(countries).map(key => (
                        <option
                          value={countries[key]}
                          label={countries[key]}
                          key={key}
                        />
                      ))}
                    </CustomSelect>
                  </FormField>
                  <div className="divider-1-2">
                    <Field
                      className={
                        props.touched.primaryOwner &&
                        props.touched.primaryOwner.firstName &&
                        props.errors.primaryOwner &&
                        props.errors.primaryOwner.firstName
                          ? 'is-invalid'
                          : ''
                      }
                      type="text"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={
                        props.values.primaryOwner &&
                        props.values.primaryOwner.firstName
                      }
                      id="primaryOwner.firstName"
                      name="primaryOwner.firstName"
                      label="First Name"
                      component={CustomInput}
                    />

                    <Field
                      className={
                        props.touched.primaryOwner &&
                        props.touched.primaryOwner.lastName &&
                        props.errors.primaryOwner &&
                        props.errors.primaryOwner.lastName
                          ? 'is-invalid'
                          : ''
                      }
                      type="text"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={
                        props.values.primaryOwner &&
                        props.values.primaryOwner.lastName
                      }
                      id="primaryOwner.lastName"
                      name="primaryOwner.lastName"
                      label="Last Name"
                      component={CustomInput}
                    />
                  </div>
                  <Field
                    className={
                      props.touched.primaryOwner &&
                      props.touched.primaryOwner.workEmail &&
                      props.errors.primaryOwner &&
                      props.errors.primaryOwner.workEmail
                        ? 'is-invalid'
                        : ''
                    }
                    type="text"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={
                      props.values.primaryOwner &&
                      props.values.primaryOwner.workEmail
                    }
                    id="primaryOwner.workEmail"
                    name="primaryOwner.workEmail"
                    label="Work Email Address"
                    component={CustomInput}
                  />
                  <FormField>
                    <CustomSelect
                      label="Job Title"
                      name="primaryOwner.jobTitle"
                      value={
                        props.values.primaryOwner &&
                        props.values.primaryOwner.jobTitle
                      }
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    >
                      <option value="" label="" />
                      {Object.keys(jobDescriptions).map(key => (
                        <option
                          value={jobDescriptions[key]}
                          label={jobDescriptions[key]}
                          key={key}
                        />
                      ))}
                    </CustomSelect>
                  </FormField>
                  <div style={{ width: '50%' }}>
                    <Field
                      className={
                        props.touched.primaryOwner &&
                        props.touched.primaryOwner.dateOfBirth &&
                        props.errors.primaryOwner &&
                        props.errors.primaryOwner.dateOfBirth
                          ? 'is-invalid'
                          : ''
                      }
                      type="text"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={
                        props.values.primaryOwner &&
                        props.values.primaryOwner.dateOfBirth
                      }
                      id="primaryOwner.dateOfBirth"
                      name="primaryOwner.dateOfBirth"
                      label="Date of Birth"
                      component={CustomInput}
                    />
                    <Field
                      className={
                        props.touched.primaryOwner &&
                        props.touched.primaryOwner.last4SSN &&
                        props.errors.primaryOwner &&
                        props.errors.primaryOwner.last4SSN
                          ? 'is-invalid'
                          : ''
                      }
                      type="text"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={
                        props.values.primaryOwner &&
                        props.values.primaryOwner.last4SSN
                      }
                      id="primaryOwner.last4SSN"
                      name="primaryOwner.last4SSN"
                      label="Last 4 SSN"
                      component={CustomInput}
                    />
                  </div>
                  <Field
                    className={
                      props.touched.primaryOwner &&
                      props.touched.primaryOwner.streetAddress &&
                      props.errors.primaryOwner &&
                      props.errors.primaryOwner.streetAddress
                        ? 'is-invalid'
                        : ''
                    }
                    type="text"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={
                      props.values.primaryOwner &&
                      props.values.primaryOwner.streetAddress
                    }
                    id="primaryOwner.streetAddress"
                    name="primaryOwner.streetAddress"
                    label="Street Address"
                    component={CustomInput}
                  />
                  <Field
                    className={
                      props.touched.primaryOwner &&
                      props.touched.primaryOwner.houseDetail &&
                      props.errors.primaryOwner &&
                      props.errors.primaryOwner.houseDetail
                        ? 'is-invalid'
                        : ''
                    }
                    type="text"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={
                      props.values.primaryOwner &&
                      props.values.primaryOwner.houseDetail
                    }
                    id="primaryOwner.houseDetail"
                    name="primaryOwner.houseDetail"
                    label="Apt., Floor, Suite or Bldg. #"
                    component={CustomInput}
                  />
                  <div className="divider-1-2">
                    <Field
                      className={
                        props.touched.primaryOwner &&
                        props.touched.primaryOwner.zip &&
                        props.errors.primaryOwner &&
                        props.errors.primaryOwner.zip
                          ? 'is-invalid'
                          : ''
                      }
                      type="text"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={
                        props.values.primaryOwner &&
                        props.values.primaryOwner.zip
                      }
                      id="primaryOwner.zip"
                      name="primaryOwner.zip"
                      label="Zip Code"
                      component={CustomInput}
                    />
                    <FormField>
                      <CustomSelect
                        label="State"
                        name="primaryOwner.state"
                        value={
                          props.values.primaryOwner &&
                          props.values.primaryOwner.state
                        }
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                      >
                        <option value="" label="" />
                        {Object.keys(states).map(key => (
                          <option
                            value={states[key]}
                            label={states[key]}
                            key={key}
                          />
                        ))}
                      </CustomSelect>
                    </FormField>
                  </div>
                </div>
                <div>
                  <FieldArray name="secondaryOwners">
                    {({ push, pop }) => (
                      <div>
                        <div className="add-additional-owner-box">
                          <h3>Additional Owners ?</h3>
                          <p>
                            Do more than one person(s) own at least 25% of the
                            company? If yes, please add all owners information
                            for verification below.
                          </p>
                          <Switch
                            checked={props.values.hasMultipleOwners}
                            onChange={checked =>
                              props.setFieldValue('hasMultipleOwners', checked)
                            }
                          />
                          {getIn(props.values, 'hasMultipleOwners') ? (
                            <div>
                              <hr />
                              <p>
                                How many additional owners own at least 25% of
                                the company?
                              </p>
                              <Button
                                type="button"
                                onClick={() =>
                                  push({ id: generate(), ...OwnerObject })
                                }
                              >
                                Add
                              </Button>
                              <Button type="button" onClick={() => pop()}>
                                Remove
                              </Button>
                            </div>
                          ) : null}
                        </div>
                        {props.values.secondaryOwners &&
                          props.values.secondaryOwners.map((owner, index) => {
                            return (
                              <div style={{ marginTop: 24 }} key={owner.id}>
                                <h3>Seconday Owner</h3>
                                <FormField>
                                  <CustomSelect
                                    label="Country"
                                    name={`secondaryOwners[${index}].citizenship`}
                                    value={owner.citizenship}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                  >
                                    <option value="" label="" />
                                    {Object.keys(countries).map(key => (
                                      <option
                                        value={countries[key]}
                                        label={countries[key]}
                                        key={key}
                                      />
                                    ))}
                                  </CustomSelect>
                                </FormField>
                                <div className="divider-1-2">
                                  <Field
                                    className={
                                      getIn(
                                        props.touched,
                                        `secondaryOwners[${index}].firstName`
                                      ) &&
                                      getIn(
                                        props.errors,
                                        `secondaryOwners[${index}].firstName`
                                      )
                                        ? 'is-invalid'
                                        : ''
                                    }
                                    type="text"
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={owner.firstName}
                                    name={`secondaryOwners[${index}].firstName`}
                                    id={`secondaryOwners[${index}].firstName`}
                                    label="First Name"
                                    component={CustomInput}
                                  />

                                  <Field
                                    className={
                                      getIn(
                                        props.touched,
                                        `secondaryOwners[${index}].lastName`
                                      ) &&
                                      getIn(
                                        props.errors,
                                        `secondaryOwners[${index}].lastName`
                                      )
                                        ? 'is-invalid'
                                        : ''
                                    }
                                    type="text"
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={owner.lastName}
                                    name={`secondaryOwners[${index}].lastName`}
                                    id={`secondaryOwners[${index}].lastName`}
                                    label="Last Name"
                                    component={CustomInput}
                                  />
                                </div>
                                <Field
                                  className={
                                    getIn(
                                      props.touched,
                                      `secondaryOwners[${index}].workEmail`
                                    ) &&
                                    getIn(
                                      props.errors,
                                      `secondaryOwners[${index}].workEmail`
                                    )
                                      ? 'is-invalid'
                                      : ''
                                  }
                                  type="text"
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  value={owner.workEmail}
                                  id={`secondaryOwners[${index}].workEmail`}
                                  name={`secondaryOwners[${index}].workEmail`}
                                  label="Work Email Address"
                                  component={CustomInput}
                                />
                                <FormField>
                                  <CustomSelect
                                    label="Job Title"
                                    name={`secondaryOwners[${index}].jobTitle`}
                                    value={owner.jobTitle}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                  >
                                    <option value="" label="" />
                                    {Object.keys(jobDescriptions).map(key => (
                                      <option
                                        value={jobDescriptions[key]}
                                        label={jobDescriptions[key]}
                                        key={key}
                                      />
                                    ))}
                                  </CustomSelect>
                                </FormField>
                                <div style={{ width: '50%' }}>
                                  <Field
                                    className={
                                      getIn(
                                        props.touched,
                                        `secondaryOwners[${index}].dateOfBirth`
                                      ) &&
                                      getIn(
                                        props.errors,
                                        `secondaryOwners[${index}].dateOfBirth`
                                      )
                                        ? 'is-invalid'
                                        : ''
                                    }
                                    type="text"
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={owner.dateOfBirth}
                                    id={`secondaryOwners[${index}].dateOfBirth`}
                                    name={`secondaryOwners[${index}].dateOfBirth`}
                                    label="Date of Birth"
                                    component={CustomInput}
                                  />
                                  <Field
                                    className={
                                      getIn(
                                        props.touched,
                                        `secondaryOwners[${index}].last4SSN`
                                      ) &&
                                      getIn(
                                        props.errors,
                                        `secondaryOwners[${index}].last4SSN`
                                      )
                                        ? 'is-invalid'
                                        : ''
                                    }
                                    type="text"
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={owner.last4SSN}
                                    id={`secondaryOwners[${index}].last4SSN`}
                                    name={`secondaryOwners[${index}].last4SSN`}
                                    label="Last 4 of SSN"
                                    component={CustomInput}
                                  />
                                </div>
                                <Field
                                  className={
                                    getIn(
                                      props.touched,
                                      `secondaryOwners[${index}].streetAddress`
                                    ) &&
                                    getIn(
                                      props.errors,
                                      `secondaryOwners[${index}].streetAddress`
                                    )
                                      ? 'is-invalid'
                                      : ''
                                  }
                                  type="text"
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  value={owner.streetAddress}
                                  id={`secondaryOwners[${index}].streetAddress`}
                                  name={`secondaryOwners[${index}].streetAddress`}
                                  label="Street Address"
                                  component={CustomInput}
                                />
                                <Field
                                  className={
                                    getIn(
                                      props.touched,
                                      `secondaryOwners[${index}].houseDetail`
                                    ) &&
                                    getIn(
                                      props.errors,
                                      `secondaryOwners[${index}].houseDetail`
                                    )
                                      ? 'is-invalid'
                                      : ''
                                  }
                                  type="text"
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  value={owner.houseDetail}
                                  id={`secondaryOwners[${index}].houseDetail`}
                                  name={`secondaryOwners[${index}].houseDetail`}
                                  label="Apt., Floor, Suite or Bldg. #"
                                  component={CustomInput}
                                />
                                <div className="divider-1-2">
                                  <Field
                                    className={
                                      getIn(
                                        props.touched,
                                        `secondaryOwners[${index}].zip`
                                      ) &&
                                      getIn(
                                        props.errors,
                                        `secondaryOwners[${index}].zip`
                                      )
                                        ? 'is-invalid'
                                        : ''
                                    }
                                    type="text"
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={owner.zip}
                                    id={`secondaryOwners[${index}].zip`}
                                    name={`secondaryOwners[${index}].zip`}
                                    label="Zip Code"
                                    component={CustomInput}
                                  />
                                  <FormField>
                                    <CustomSelect
                                      label="State"
                                      name={`secondaryOwners[${index}].state`}
                                      value={owner.state}
                                      onChange={props.handleChange}
                                      onBlur={props.handleBlur}
                                    >
                                      <option value="" label="" />
                                      {Object.keys(states).map(key => (
                                        <option
                                          value={states[key]}
                                          label={states[key]}
                                          key={key}
                                        />
                                      ))}
                                    </CustomSelect>
                                  </FormField>
                                </div>
                              </div>
                            )
                          })}
                      </div>
                    )}
                  </FieldArray>
                </div>
              </div>
            </div>
            <CardFooter
              style={{ alignItems: 'center' }}
              className="on-boarding-form-actions"
            >
              <Link to="/">Go Back</Link>
              <Button
                isColor={props.isValid ? 'primary' : ''}
                className="is-rounded"
                type="submit"
              >
                Continue
              </Button>
            </CardFooter>
          </form>
        )}
      />
    )
  }
}

export default CCorpPersonalVerificationForm
