import React, { Component } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import {
  Field as FormField,
  CustomSelect,
  Button,
  CardFooter
} from 'sqrl-design-system'

import { BusinessTypes } from '../data'

const businessTypes = BusinessTypes

const BusinessTypeSchema = Yup.object().shape({
  business_type: Yup.string()
    .required('Business type is required')
    .oneOf(businessTypes, 'Business type is invalid')
})

class BusinessTypeForm extends Component {
  render() {
    return (
      <div className="business-type-form">
        <h1>Type of Business</h1>
        <p>
          Please identify the type of business you’re representing. This
          information will help us determine the necessary next steps to verify
          your business for the security of you and the business.
        </p>
        <Formik
          initialValues={{}}
          validationSchema={BusinessTypeSchema}
          onSubmit={(values, { setSubmitting }) => {
            console.log(values)
            setSubmitting(false)
          }}
          render={props => (
            <form name="businessType" onSubmit={props.handleSubmit}>
              <FormField>
                <CustomSelect
                  className={
                    props.touched.business_type && props.errors.business_type
                      ? 'is-invalid'
                      : ''
                  }
                  label="Type of Business"
                  name="business_type"
                  value={props.values.business_type}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                >
                  <option value="" label="" />
                  {businessTypes.map((cat, i) => (
                    <option
                      value={cat}
                      label={cat}
                      key={`BusinessType${i.toString()}`}
                    />
                  ))}
                </CustomSelect>
              </FormField>

              <CardFooter className="form-footer" style={{ padding: '30px 0' }}>
                <Button
                  isColor={props.isValid ? 'primary' : ''}
                  className="is-rounded"
                  type="submit"
                >
                  Continue
                </Button>
              </CardFooter>
            </form>
          )}
        />
      </div>
    )
  }
}

export default BusinessTypeForm
