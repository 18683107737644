import React, { Component } from 'react'
import {
  Section,
  Container,
  Card,
  CardHeader,
  CardContent,
  FlatLogo
} from 'sqrl-design-system'

import { CCorpPersonalVerificationForm } from '../forms/onboarding'
import BusinessInfoSteps from './BusinessInfoSteps'

class CCorpPersonalVerification extends Component {
  render() {
    return (
      <Section>
        <Container>
          <Card
            style={{ maxWidth: 800, marginLeft: 'auto', marginRight: 'auto' }}
          >
            <CardHeader className="on-boarding-header">
              <div className="center-logo">
                <FlatLogo fill="#9013fe" style={{ marginRight: 5 }} />
                <span style={{ color: '#202733' }}>|{'  '}Workforce</span>
              </div>
              <BusinessInfoSteps current={1} />
            </CardHeader>
            <CardContent style={{ padding: 0 }}>
              <CCorpPersonalVerificationForm />
            </CardContent>
          </Card>
        </Container>
      </Section>
    )
  }
}

export default CCorpPersonalVerification
