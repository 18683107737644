import React, { Component } from 'react'
import { Link } from 'gatsby'
import { Field, Formik } from 'formik'
import * as Yup from 'yup'

import {
  Button,
  CardFooter,
  CustomInput,
  CustomSelect,
  Field as FormField
} from 'sqrl-design-system'
import MaskedInput from 'react-text-mask'

import { JobDescriptions, PhoneMask } from '../data'

const phoneNumberMask = PhoneMask

const jobDescriptions = JobDescriptions

const FormSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  workEmail: Yup.string()
    .required('Email is required')
    .email('Email is not valid'),
  jobDesc: Yup.string()
    .required('Job Description is required')
    .oneOf(jobDescriptions, 'Job Description is not valid'),
  phone: Yup.string()
    .required('Phone is required')
    .length(14),
  dateOfBirth: Yup.string().required('Date of Birth is required'),
  last4SSN: Yup.string()
    .required('Last 4 SSN digits are required')
    .length(4, 'Enter all 4 last digits of SSN')
})

class CCorpPersonalVerificationForm extends Component {
  render() {
    return (
      <Formik
        initialValues={{}}
        validationSchema={FormSchema}
        onSubmit={(values, { setSubmitting }) => {
          console.log(values)
          setSubmitting(false)
        }}
        render={props => (
          <form
            name="c-corp-personal-verification"
            onSubmit={props.handleSubmit}
          >
            <div className="c-corp-personal-verification-form-container">
              <h1>Personal Verification Info</h1>
              <p>
                In order to setup your account properly, please review the
                information you’ve already provided as well as some additional
                details we need in order to verify your personal and business
                identity. We ask for this information solely as a precautionary
                measure for your security.
              </p>
              <hr />
              <div className="details-section">
                <h2>Personal Details</h2>
                <div className="divider-1-2">
                  <Field
                    className={
                      props.touched.firstName && props.errors.firstName
                        ? 'is-invalid'
                        : ''
                    }
                    type="text"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.firstName}
                    id="firstName"
                    name="firstName"
                    label="First Name"
                    component={CustomInput}
                  />

                  <Field
                    className={
                      props.touched.lastName && props.errors.lastName
                        ? 'is-invalid'
                        : ''
                    }
                    type="text"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.lastName}
                    id="lastName"
                    name="lastName"
                    label="Last Name"
                    component={CustomInput}
                  />
                </div>

                <Field
                  className={
                    props.touched.workEmail && props.errors.workEmail
                      ? 'is-invalid'
                      : ''
                  }
                  type="text"
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.workEmail}
                  id="workEmail"
                  name="workEmail"
                  label="Work Email Address"
                  component={CustomInput}
                />

                <Field
                  type="text"
                  name="phone"
                  render={({ field }) => (
                    <FormField>
                      <MaskedInput
                        className={
                          props.touched.phone && props.errors.phone
                            ? 'is-invalid'
                            : ''
                        }
                        {...field}
                        render={(ref, props) => (
                          <CustomInput {...field} {...props} innerRef={ref} />
                        )}
                        mask={phoneNumberMask}
                        guide={false}
                        id="phone"
                        label="Company Phone"
                        type="text"
                      />
                    </FormField>
                  )}
                />

                <FormField>
                  <CustomSelect
                    className={
                      props.touched.jobDesc && props.errors.jobDesc
                        ? 'is-invalid'
                        : ''
                    }
                    name="jobDesc"
                    label="Job Description"
                    value={props.values.jobDesc}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  >
                    <option value="" label="" />
                    {jobDescriptions.map((s, i) => (
                      <option
                        value={s}
                        label={s}
                        key={`JobDesc${i.toString()}`}
                      />
                    ))}
                  </CustomSelect>
                </FormField>

                <div style={{ width: '50%' }}>
                  <Field
                    className={
                      props.touched.dateOfBirth && props.errors.dateOfBirth
                        ? 'is-invalid'
                        : ''
                    }
                    type="text"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.dateOfBirth}
                    id="dateOfBirth"
                    name="dateOfBirth"
                    label="Date of Birth"
                    component={CustomInput}
                  />

                  <Field
                    className={
                      props.touched.last4SSN && props.errors.last4SSN
                        ? 'is-invalid'
                        : ''
                    }
                    type="text"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.last4SSN}
                    id="last4SSN"
                    name="last4SSN"
                    label="Last 4 SSN"
                    component={CustomInput}
                  />
                </div>
              </div>
            </div>
            <CardFooter
              style={{ alignItems: 'center' }}
              className="on-boarding-form-actions"
            >
              <Link to="/">Save Progress</Link>
              <Button
                isColor={props.isValid ? 'primary' : ''}
                className="is-rounded"
                type="submit"
              >
                Continue
              </Button>
            </CardFooter>
          </form>
        )}
      />
    )
  }
}

export default CCorpPersonalVerificationForm
