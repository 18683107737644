import React, { Component } from 'react'
import { SolePropPersonalVerification } from '../components/OnBoarding'

class OnBoardingSolePropPersonalVerification extends Component {
  render() {
    return (
      <div>
        <SolePropPersonalVerification />
      </div>
    )
  }
}

export default OnBoardingSolePropPersonalVerification
